<template>
  <div class="home-page">
    <div class="app-container">
      <el-card shadow="always" class="room-name">
        <div>{{ room.name }}</div>
      </el-card>

      <el-card shadow="always" class="chat-layout">
        <div id="messages" @scroll="scrollMessageHandler()">
          <message-item v-for="message in messages" :key="message.id" :message="message" :customer="customer" />
        </div>
        <el-row>
          <chat-form :room-id="roomId" />
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, nextTick } from 'vue'
import { getHistory, MESSAGE_PER_PAGE, getRoomDetail } from '@/api/chat'
import { getCustomerInfo } from '@/api/user'
import { useRoute } from 'vue-router'
import MessageItem from '@/views/chat/message/Item.vue'
import ChatForm from '@/views/chat/ChatForm.vue'
import { useEcho } from '@/functions/useEcho'
import { useStore } from 'vuex'
import { reverse } from 'lodash'

export default defineComponent({
  name: 'ChatRoomDetail',
  components: {
    MessageItem,
    ChatForm
  },

  setup() {
    const route = useRoute()
    const { echo } = useEcho()
    const roomId = route.params.id
    const userId = useStore().getters.user_id
    const customerId = ref(0)
    const messages = ref([])
    const pager = reactive({
      page: 1,
      per_page: MESSAGE_PER_PAGE,
      total: 0,
      last_page: 0
    })

    const room = reactive({
      name: null,
      created_at: null
    })

    const customer = reactive({
      name: '',
      avatar: ''
    })

    const getCustomerId = () => {
      return new Promise((resolve) => {
        messages.value.forEach(message => {
          if (message.sender !== userId) {
            customerId.value = message.sender
            resolve()
            return false
          }
        })
      })
    }

    const chatRoomDetail = () => {
      getRoomDetail(roomId).then(({ data }) => {
        room.name = data.name
        room.created_at = data.created_at
      })
    }

    const fetchCustomerInfo = () => {
      getCustomerId().then(() => {
        getCustomerInfo(customerId.value).then(({ data }) => {
          customer.name = data.name
          customer.avatar = data.avatar ? process.env.VUE_APP_BASE_STORAGE + '/' + data.avatar : null
        })
      })
    }

    const scrollToLatestMessage = () => {
      const elem = document.getElementById('messages')
      elem.scrollTop = elem.scrollHeight
    }

    const addMessages = (newMessages, shouldPrefix = false) => {
      return new Promise(resolve => {
        newMessages.forEach(newMessage => {
          hasNewMessage(newMessage, shouldPrefix)
        })
        resolve()
      })
    }

    const fetchHistory = (shouldPrefix = false) => {
      getHistory(roomId, pager).then(({ data }) => {
        pager.page = data.current_page
        pager.per_page = data.per_page
        pager.total = data.total
        pager.last_page = data.last_page
        addMessages(reverse(data.data), shouldPrefix).then(() => fetchCustomerInfo())
      })
    }

    const hasNewMessage = (message, shouldPrefix = false) => {
      if (shouldPrefix) {
        messages.value.unshift(message)
      } else {
        messages.value.push(message)
      }

      nextTick(() => {
        if (!shouldPrefix) scrollToLatestMessage()
      })
    }

    const scrollMessageHandler = () => {
      const container = document.getElementById('messages')
      if (!container || pager.last_page === pager.page) return
      if (container.scrollTop === 0 && pager.page < pager.last_page) {
        pager.page++
        fetchHistory(true)
      }
    }

    const responsiveHeight = () => {
      const responsive = window.innerHeight - 350
      document.getElementById('messages').style.height = responsive + 'px'
    }

    onMounted(() => {
      chatRoomDetail()
      responsiveHeight()
      fetchHistory()
      echo.private('groups_' + roomId)
        .listen('MessageSent', e => {
          hasNewMessage(e.message)
        })
    })

    return {
      messages,
      pager,
      roomId,
      scrollMessageHandler,
      responsiveHeight,
      getRoomDetail,
      customer,
      room
    }
  }
})
</script>
<style lang="scss" scoped>
.room-name {
  background-color: #014e94;
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px
}
.chat-layout {
  background-color:#a0c6d9;
  height:100%
}
#messages {
  overflow-y:scroll;
  overflow-x: hidden;
  height: 100%
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #a0c6d9;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 7px
}

</style>
