<template>
  <el-form
    style="dislay:flex;justify-content:space-between;width:100%"
    :model="form"
    @submit.prevent="send"
  >
    <div id="mess" class="message-box">
      <input v-if="!urlPreview" v-model="form.message" placeholder="" class="message-input">
      <div v-if="errorMessage" class="el-form-item__error">{{ errorMessage }}</div>
      <input :id="selector" ref="fileUpload" type="file" accept="image/*" style="display:none" @change="changeImage">
      <div v-if="urlPreview">
        <div style="position:relative;width:30%">
          <el-image :src="urlPreview" style="width:100%;height:100%" fit="cover" />
          <el-button size="mini" class="el-icon-close" circle style="position:absolute;top: -10px;right: -10px" @click="handleDeleteImage" />
        </div>
      </div>
      <el-button id="btn-submit" class="message-submit" @click="send">
        送信
      </el-button>
      <el-button class="el-icon-upload btn-upload" @click="$refs.fileUpload.click()" />
    </div>
  </el-form>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue'
import { sendMessage, uploadImage } from '@/api/chat'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ChatForm',
  props: {
    roomId: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const errorMessage = ref('')
    const file = ref(null)
    const selector = 'file-upload'
    const urlPreview = ref(null)
    const form = reactive({
      message: '',
      sender: store.getters.user_id,
      chatroom_id: props.roomId,
      chat_type: 1,
      message_type: 0
    })

    const changeImage = (e) => {
      const fileSelector = document.querySelector('#' + selector)
      if (!fileSelector) return
      if (fileSelector.files && fileSelector.files.length) {
        file.value = fileSelector.files[0]
        urlPreview.value = URL.createObjectURL(file.value)
        clearErrorMessage()
      }
    }

    const handleDeleteImage = () => {
      const fileSelector = document.querySelector('#' + selector)
      urlPreview.value = null
      file.value = null
      fileSelector.value = ''
    }

    const sendImage = () => {
      return uploadImage(file.value, props.roomId).then(({ data }) => {
        form.message = data.url
        form.message_type = 1
        cleanFileUpload()
        send()
      })
    }

    const cleanFileUpload = () => {
      file.value = ''
    }

    const cleanMessage = () => {
      form.message = ''
      form.message_type = 0
    }

    const clearErrorMessage = () => {
      errorMessage.value = ''
    }

    const send = () => {
      if (!form.message.length && !file.value) {
        errorMessage.value = 'メッセージを入力してください'
        return
      }

      if (file.value) {
        sendImage()
        urlPreview.value = ''
      } else {
        sendMessage(form).then(() => cleanMessage())
      }
    }

    watch(form, () => clearErrorMessage(), { deep: true })

    return {
      form,
      file,
      selector,
      errorMessage,
      send,
      urlPreview,
      handleDeleteImage,
      changeImage
    }
  }
})
</script>
<style lang="scss" scoped>
.message-box {
  flex: 0 1 42px;
  width: 100%;
  background: #fff;
  margin:2px auto;
  padding: 10px;
  position: relative;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 30px;
  height:100%;
  border:1px solid #ccc;

  .message-input {
    background: none;
    border: none;
    outline: none!important;
    resize: none;
    font-size: 15px;
    height: 24px;
    margin: 0;
    padding-right: 20px;
    width: 85%;
    color: #444;
  }
  input[type="text"]:focus:-webkit-placeholder {
    color: transparent;
  }
  .message-submit {
    position: absolute;
    z-index: 1;
    top: 6px;
    right: 10px;
    color: #fff;
    border: none;
    background: #014e94;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    padding: 6px 16px;
    border-radius: 20px;
    outline: none!important;
    transition: background .2s ease;
    cursor:pointer;
  }
  .btn-upload {
    position: absolute;
    z-index: 1;
    top: 6px;
    right: 70px;
    color: #014e94;
    border: none;
    background: #fff;
    font-size: 25px;
    text-transform: uppercase;
    line-height: 1;
    padding: 6px 10px;
    border-radius: 20px;
    outline: none!important;
    transition: background .2s ease;
    cursor:pointer;
  }
}
</style>
