<template>
  <div>
    <el-image
      style="width: 200px;max-height: 300px;object-fit:cover;cursor:pointer"
      :src="imageUrl"
      @click="imagePreview = true"
    />

    <!-- Dialog -->
    <el-dialog v-model="imagePreview">
      <el-image
        style="width: 100%; height: 50%;padding: 20px"
        :src="imageUrl"
      />
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImageMessage',
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imagePreview: false
    }
  },
  computed: {
    imageUrl() {
      return process.env.VUE_APP_BASE_STORAGE + '/' + this.message
    }
  }
})
</script>
