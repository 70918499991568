<template>
  <el-row v-if="message.chat_type === 1" :gutter="20" style="flex-direction: row-reverse;margin-left:0 !important;margin-right:0 !important" class="mb-30">
    <el-col :span="2" style="padding-top:20px">
      <span>
        <el-image class="avatar" src="/images/avatar_admin.png" alt="" fit="cover" /></span>
    </el-col>
    <el-col :span="16" style="display:flex;justify-content:flex-end">
      <div>
        <el-row>
          <span>{{ admin.name }}</span>
        </el-row>
        <message-content :message="message.message" :type="message.message_type" class="message-item-user" />
      </div>
    </el-col>

  </el-row>
  <el-row v-else-if="message.chat_type === 0" :gutter="20" class="mb-30">
    <el-col :span="2" style="padding-top:20px">
      <span>
        <el-image v-if="!customer.avatar" class="avatar" fit="cover" src="/images/avatar_admin.png" alt="" />
        <el-image v-else class="avatar" fit="cover" :src="customer.avatar" alt="" /></span>
    </el-col>
    <el-col :span="16">
      <div>
        <el-row>
          <span>{{ customer.name }}</span>
        </el-row>
        <message-content :message="message.message" :type="message.message_type" class="message-item-admin" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import MessageContent from '@/views/chat/message/Content.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MessageItem',
  components: {
    MessageContent
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const admin = reactive({
      name: store.getters.name,
      avatar: store.getters.avatar
    })
    return { admin }
  }
})
</script>
<style lang="scss" scoped>
.message-item-admin {
  background-color: #a7dcf6;
  padding: 20px;
  color: #355790;
  margin: 5px 0 20px 0;
  border-radius: 7px;
  display:inline-block;

}
.message-item-user {
  background-color: #fff;
  color: #355790;
  padding: 20px;
  margin: 5px 0 20px 0;
  border-radius: 7px;
  display:inline-block;
}
.avatar {
  width:50px;
  height:50px;
  border-radius:50%

}
</style>
