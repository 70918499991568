<template>
  <message-text v-if="type === 0" :message="message" />
  <message-image v-else-if="type === 1" :message="message" />
</template>

<script>
import { defineComponent } from 'vue'
import MessageText from '@/views/chat/message/Text.vue'
import MessageImage from '@/views/chat/message/Image.vue'

export default defineComponent({
  name: 'MessageContent',
  components: {
    MessageText,
    MessageImage
  },
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  }
})
</script>
